import React, { useState, useEffect } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import SearchField from 'components/ui-components/SearchField';
import '../styles/search.scss';

interface Props {
    searchTerm: string;
    searchPlaceholder?: string;
    forceSearchTerm?: string;
    onMutation: (value: string) => void;
}

const TabbarSearch = ({ searchTerm, searchPlaceholder, forceSearchTerm, onMutation }: Props) => {
    const [showSearch, setShowSearch] = useState<boolean>(false);

    const onClickAway = () => {
        if (searchTerm === '') {
            setShowSearch(false);
        }
    };

    return (
        <div className="tabbar-search" data-cy="tabbar-search-field">
            {!showSearch && (
                <Tooltip title={searchTerm}>
                    <span>
                        <Button onClick={() => setShowSearch(true)} endIcon={<Icon>search</Icon>}>
                            {Translation.get('labels.search', 'common')}
                            {searchTerm.length > 0 && <div className="tabbar-search__badge">…</div>}
                        </Button>
                    </span>
                </Tooltip>
            )}
            {showSearch && (
                <ClickAwayListener onClickAway={onClickAway}>
                    <div className="tabbar-search__field">
                        <SearchField
                            autoFocus
                            searchTerm={searchTerm}
                            forceSearchTerm={forceSearchTerm}
                            onChange={(_, value: string) => onMutation(value)}
                            searchPlaceholder={searchPlaceholder}
                        />
                    </div>
                </ClickAwayListener>
            )}
        </div>
    );
};

export default TabbarSearch;
